import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { closeLeadDialog, leadSubmitted } from "../../actions/leadDialog";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import Input from "../../components/Input";
import Dialog from "../../containers/Dialog";
import { phoneNormalizer, phoneNumberFormatter, PHONE_MASK } from "../../formatters/phoneNumber";
import formValidation from "../../validations/formValidation";
import isEmail from "../../validations/isEmail";
import isLength from "../../validations/isLength";
import isNotEmpty from "../../validations/isNotEmpty";
import isPhoneNumber, { PHONE_PREFIX } from "../../validations/isPhoneNumber";
import styles from "./styles.scss";
import theme from "./theme.scss";
import locs from "../../localization";
import isChecked from "../../validations/isChecked";
import { PPAS_WEB_URL } from "../../constants";
import isAlphaWithSpaces from "../../validations/isAlphaWithSpaces";

const formName = "calculator";
const contactName = "contact.person";

interface LeadDialogProps {}

const mapStateToProps = (state: { lead: { isOpen: boolean; submitted: boolean } }) => ({
  isOpen: state.lead.isOpen,
  submitted: state.lead.submitted,
});

const mapDispatchToProps = {
  closeLeadDialog,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const LeadDialog = ({
  submitted,
  submitting,
  isOpen,
  closeLeadDialog,
  handleSubmit,
}: LeadDialogProps & InjectedFormProps & PropsFromRedux) => {
  const handleRedirect = () => {
    window.location.href = PPAS_WEB_URL;
  };

  const content = (
    <div className={styles.mainContent}>
      <h2>{locs("titles.leadModal")}</h2>
      <p>{locs("texts.leadModalText")}</p>
      <Field
        name={`${contactName}.name`}
        id="givenName"
        autoComplete="given-name"
        type="text"
        component={Input}
        props={{
          theme,
        }}
        validate={[formValidation(isNotEmpty), formValidation(isAlphaWithSpaces), formValidation(isLength({ max: 40 }))]}
        label={locs("labels.name")}
      />
      <Field
        name={`${contactName}.surname`}
        id="surname"
        autoComplete="family-name"
        type="text"
        component={Input}
        props={{
          theme,
        }}
        validate={[formValidation(isNotEmpty), formValidation(isAlphaWithSpaces), formValidation(isLength({ max: 40 }))]}
        label={locs("labels.surname")}
      />
      <Field
        name={`${contactName}.phone`}
        id="phone"
        autoComplete="phone"
        type="text"
        component={Input}
        props={{
          mask: PHONE_MASK as string[],
          prefix: PHONE_PREFIX,
          placeholder: locs("placeholders.phone"),
          theme,
        }}
        validate={[formValidation(isNotEmpty), formValidation(isLength({ max: 30 })), formValidation(isPhoneNumber)]}
        format={phoneNumberFormatter}
        normalize={phoneNormalizer}
        label={locs("labels.phone")}
      />
      <Field
        name={`${contactName}.email`}
        id="email"
        autoComplete="email"
        type="text"
        component={Input}
        props={{
          theme,
        }}
        validate={[formValidation(isLength({ max: 100 })), formValidation(isEmail)]}
        label={locs("labels.email") + " " + locs("labels.optional")}
      />

      <Field
        name={`${contactName}.leadAgreement`}
        id="message"
        type="checkbox"
        component={Checkbox}
        props={{
          dangerHTML: true,
          theme,
        }}
        label={locs("texts.leadModalAgreement")}
        validate={[formValidation(isChecked)]}
      />
      <hr />
      <Button disabled={submitting} onClick={handleSubmit}>
        {locs("actions.callMe")}
      </Button>
    </div>
  );

  const thanksContent = (
    <div className={styles.thanksContent}>
      <h2>{locs("titles.leadModalThanks")}</h2>
      <p>{locs("texts.leadModalThanksText")}</p>
      <Button onClick={handleRedirect}>{locs("actions.backToMainWeb")}</Button>
    </div>
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog closable={!submitted} onClose={closeLeadDialog}>
      <div className={styles.innerDialog}>{submitted ? thanksContent : content}</div>
    </Dialog>
  );
};

export default compose(
  reduxForm({
    form: formName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (_result, dispatch, _props) => {
      dispatch(leadSubmitted());
    },
  }),
  connector
)(LeadDialog);
