import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";
import Button from "../../../components/Button";
import { LeadSource, openLeadDialog } from "../../../actions/leadDialog";
import { connect } from "react-redux";
import locs from "../../../localization";

interface ProductStubProps {
  openLeadDialog: (source: LeadSource) => void;
}

const mapDispatchToProps = {
  openLeadDialog,
};

const ProductStub = ({ openLeadDialog }: ProductStubProps) => {
  return (
    <div className={classNames(styles.product_container)}>
      <div className={styles.title_container}>
        <h2 className={styles.title}>{locs("titles.productStub")}</h2>
      </div>
      <div className={styles.body}>
        <div className={styles.description_container}>
          <div className={styles.description_inner} style={{ alignContent: "center" }}>
            <p style={{ whiteSpace: "pre" }}>{locs("texts.productStub")}</p>
          </div>
        </div>
        <div className={styles.price_container}>
          <div className={styles.price_inner}>
            <Button
              className={classNames(styles.select_product)}
              onClick={() => {
                openLeadDialog("product_stub");
              }}>
              {locs("actions.callMe")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(ProductStub);
