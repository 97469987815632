import React, { PureComponent } from "react";
import classNames from "classnames";
import locs from "../../../localization";

import TooltipContainer from "../../../containers/TooltipContainer";
import Button from "../../../components/Button";
import PriceDetail from "./PriceDetail";

import { PERIOD_MONTH, PERIOD_YEAR } from "../../../constants";
import { priceFormatter, seleniumClass } from "../../../helpers";
import { productPriceConversion } from "../../../helpers/price";
import styles from "./styles.scss";
import selectStyles from "../../../components/Select/styles.scss";
import { Product } from "../../../reducers/types";

export interface ProductRowProps {
  title: string;
  top: boolean;
  variants: Product[];
  pricePeriod?: typeof PERIOD_MONTH | typeof PERIOD_YEAR;
  onSelect: (value: Product) => void;
}

export default class ProductRow extends PureComponent<ProductRowProps, { selected: Product }> {
  state = {
    selected: this.props.variants[0],
  };

  getVariantTitle = variant => locs(`contractDuration.${variant.prices.contractDuration}`);

  handleSelectVariant = ev => {
    const { value } = ev.target;
    const selected = this.props.variants.find(product => product.value === value);
    this.setState({ selected });
  };

  handleSelect = () => {
    this.props.onSelect(this.state.selected);
  };

  renderSelectVariant = () => {
    const { variants } = this.props;

    return (
      <div className={classNames(selectStyles.select, styles.duration)}>
        <select onChange={this.handleSelectVariant}>
          {variants.map(product => (
            <option key={product.value} value={product.value}>
              {this.getVariantTitle(product)}
            </option>
          ))}
        </select>
      </div>
    );
  };

  render() {
    const { title, top, variants, pricePeriod } = this.props;
    const { selected } = this.state;
    const prices = productPriceConversion(selected.prices, PERIOD_YEAR, pricePeriod);

    const price = priceFormatter(prices.total);
    const priceSuffix = pricePeriod === PERIOD_YEAR ? locs("labels.czk_per_year") : locs("labels.czk_per_month");

    const tooltip = <PriceDetail className={styles.tooltip} prices={prices} />;

    return (
      <div className={classNames(styles.product_container, seleniumClass("product"))}>
        <div className={styles.title_container}>
          <h2 className={styles.title}>{title}</h2>
          {top && <div className={styles.tip}>{locs("labels.our_suggestion")}</div>}
          {variants.length > 1 && this.renderSelectVariant()}
        </div>
        <div className={styles.body}>
          <div className={styles.description_container}>
            <div className={styles.description_inner}>
              <div dangerouslySetInnerHTML={{ __html: selected.description }} />
            </div>
          </div>
          <div className={styles.priceContainer}>
            <div className={styles.price_inner}>
              <h3 className={styles.price}>{`${price} ${priceSuffix}`}</h3>
              <span className={styles.price_with_vat}>{locs("labels.price_with_vat")}</span>
              <Button className={classNames(styles.select_product, seleniumClass("select_product"))} onClick={this.handleSelect}>
                {locs("labels.select")}
              </Button>
              <TooltipContainer overlay={tooltip} placement="rightTop" align={{ offset: [4, -30] }}>
                <span className={styles.moreAboutPrice}>{locs("actions.more_about_the_price")}</span>
              </TooltipContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
