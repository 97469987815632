import React from "react";
import groupBy from "lodash/groupBy";
import values from "lodash/values";

import { Product } from "../../../reducers/types";
import ProductForm from "./ProductRow";
import ProductStub from "./ProductStub";

import { PERIOD_MONTH, PERIOD_YEAR } from "../../../constants";

export interface ProductsProps {
  products?: Product[];
  pricePeriod: typeof PERIOD_MONTH | typeof PERIOD_YEAR;
  onSelect: (value: any) => void;
  showProductStub?: boolean;
}

export default function Products(props: ProductsProps) {
  const { products = [], pricePeriod, onSelect, showProductStub } = props;

  const rows = values(groupBy(products, "externalId"))
    .map(variants => ({
      externalId: variants[0].externalId,
      title: variants[0].title,
      top: variants[0].top,
      variants,
    }))
    .map(data => <ProductForm key={data.externalId} {...data} pricePeriod={pricePeriod} onSelect={onSelect} />);

  if (showProductStub) {
    rows.splice(2, 0, <ProductStub key={"contact-offer"} />);
  }

  return <div>{rows}</div>;
}
